<template>
  <v-container
    fluid
    fill-height
    :class="$vuetify.breakpoint.mobile ? 'pa-0' : 'pa-3'"
    style="`margin: 0px; width: 100%; overflow-y: auto;`"
    :style="{background: $vuetify.theme.themes[theme].background}"
  >
    <v-card
      width="100%"
      height="100%"
      style="overflow-y: auto;"
    >
      <v-row
        no-gutters
        justify="center"
        class="pa-0"
      >
        <v-col
          :cols="$vuetify.breakpoint.mobile ? '12' : '10'"
        >
          <v-list
            expand
            :max-height="($vuetify.breakpoint.height - 152).toString()"
            class="mt-4 white"
          >
            <v-list-group
              v-for="item in dashboardSections"
              :key="item.title"
              v-model="item.active"
              :prepend-icon="item.action"
              no-action
              @change="toggleListItems(item)"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title" />
                </v-list-item-content>
              </template>
              <v-list-item>
                <QuickAddColumn
                  :is-quickstart="item.isQuickstart"
                  :is-notes="item.isNotes"
                  :is-pinboard="item.isPinboard"
                />
                <Notes
                  :is-notes="item.isNotes"
                  :is-quickstart="item.isQuickstart"
                  :is-pinboard="item.isPinboard"
                />
                <PinBoard
                  :is-pinboard="item.isPinboard"
                  :is-quickstart="item.isQuickstart"
                />
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { mdiNotebookEditOutline, mdiPin } from '@mdi/js'
import QuickAddColumn from './QuickAddColumn.vue'
import Notes from './Notes.vue'
import PinBoard from './PinBoard.vue'

export default {
  components: {
    QuickAddColumn,
    Notes,
    PinBoard
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      clients: state => state.clients.clients,
      documents: state => state.documents.documents,
      company: state => state.company.company
    }),
    theme () {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
    dashboardSections () {
      return [
        {
          action: 'mdi-ray-start-arrow',
          active: JSON.parse(localStorage.getItem('quick-start')),
          isQuickstart: true,
          isNotes: false,
          isPinboard: false,
          title: this.$t('dashboard|quick_start')
        },
        {
          action: mdiNotebookEditOutline,
          active: JSON.parse(localStorage.getItem('notes-menu')),
          isNotes: true,
          isQuickstart: false,
          isPinboard: false,
          title: this.$t('common|notes')
        },
        {
          action: mdiPin,
          active: JSON.parse(localStorage.getItem('pin-board')),
          isPinboard: true,
          isQuickstart: false,
          isNotes: false,
          title: this.$t('common|pinboard')
        }
      ]
    }
  },
  mounted () {
    this.toggleDrawer(true)
  },
  methods: {
    ...mapMutations({
      toggleDrawer: 'app/toggleDrawer'
    }),
    viewIsSelected (is, value) {
      if (is === this.$t('dashboard|quick_start') && value === true) {
        localStorage.setItem('dashboardListQuickStart', false)
      } else if (is === this.$t('dashboard|quick_start') && value === false) {
        localStorage.setItem('dashboardListQuickStart', true)
      }
      if (is === 'Notes') {
        localStorage.setItem('dashboardListNotes', value)
      }
      if (is === 'Pinboard') {
        localStorage.setItem('dashboardListNotesPinboard', value)
      }
    },
    toggleListItems (item) {
      if (item.isQuickstart && item.active) localStorage.setItem('quick-start', false)
      if (item.isQuickstart && !item.active) localStorage.setItem('quick-start', true)
      if (item.isNotes && item.active) localStorage.setItem('notes-menu', false)
      if (item.isNotes && !item.active) localStorage.setItem('notes-menu', true)
      if (item.isPinboard && item.active) localStorage.setItem('pin-board', false)
      if (item.isPinboard && !item.active) localStorage.setItem('pin-board', true)
    }
  }
}
</script>
